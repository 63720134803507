@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}
.Home_container__3l0zs {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 960px;
    margin: 60px auto;
  }
  .Home_content__3qF3z {
    padding-right: 30px;
  }
  .Home_sidebar__11Win {
    padding-left: 30px;
  }
  .Home_sidebar__11Win form {
    padding: 20px;
    background: #1f9751;
    border-radius: 10px;
  }
  .Home_sidebar__11Win input, .Home_sidebar__11Win select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
  }
  .Home_sidebar__11Win label {
    margin: 0 auto 20px;
    display: block;
    color: #fff;
  }
  .Home_sidebar__11Win button {
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
  }
  .Home_sidebar__11Win h3 {
    color: #1f9751;
    margin-bottom: 20px;
  }
  .Home_sidebar__11Win aside {
    margin-top: 40px;
    color: #555;
  }
  .Home_sidebar__11Win aside li {
    margin: 10px;
  }
  
  /* transactions */
.Home_transactions__E9Qxb li {
  margin: 30px auto;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 5px rgba(50,50,50,0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-left: 4px solid #1f9751;
}
.Home_transactions__E9Qxb .Home_name__XUwfH {
  color: #777;
  font-size: 1.4em;
}
.Home_transactions__E9Qxb .Home_amount__1TpPY {
  margin-left: auto;
  margin-right: 40px;
  color: #777;
  font-weight: bold;
  font-size: 1.6em;
}
.Home_transactions__E9Qxb button {
  position: absolute;
  top: 0;
  right:0;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
}
.Login_login-form__1JQJ6 {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
  }
  .Login_login-form__1JQJ6 label {
    display: block;
    margin: 30px auto;
  }
  .Login_login-form__1JQJ6 span {
    display: block;
    margin-bottom: 6px;
  }
  .Login_login-form__1JQJ6 input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }
.Signup_signup-form__1Vnfq {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
  }
  .Signup_signup-form__1Vnfq label {
    display: block;
    margin: 30px auto;
  }
  .Signup_signup-form__1Vnfq span {
    display: block;
    margin-bottom: 6px;
  }
  .Signup_signup-form__1Vnfq input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }
/* navbar styles */
.Navbar_navbar__1-Q-L {
    width: 100%;
    background: #effaf0;
    padding: 20px 10px;
    box-sizing: border-box;
  }
  .Navbar_navbar__1-Q-L ul {
    display: flex;
    margin: 0 auto;
    max-width: 960px;
    align-items: center;
  }
  .Navbar_title__3vHwl {
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
  }
  .Navbar_navbar__1-Q-L button, .Navbar_navbar__1-Q-L a {
    margin-left: 16px;
  }
  .Navbar_navbar__1-Q-L a {
    color: #333;
    text-decoration: none;
  }
